import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { chain, has, groupBy, cloneDeep, sortBy, isEmpty } from "lodash";
import { serviceNameToSquad } from "error-logging!sofe";
import ServiceFilter from "./service-filter.component.js";
import {
  getServiceOverrides,
  updateService,
  removeService,
  getAvailableServices,
} from "./service.resource.js";

export default forwardRef((props, ref) => {
  const { squad } = props;
  const wrapperRef = useRef();
  const [filteredSquad, setFilteredSquad] = useState(
    "cp:sofe-inspector-filter" in localStorage
      ? localStorage.getItem("cp:sofe-inspector-filter")
      : squad,
  );

  const setSquad = (squad) => {
    localStorage.setItem("cp:sofe-inspector-filter", squad);
    setFilteredSquad(squad);
  };

  useImperativeHandle(ref, () => ({
    refreshServices: () => {
      wrapperRef.current.refreshServices();
    },
    setSquad,
  }));

  return (
    <SofeOverrides
      {...props}
      filteredSquad={filteredSquad}
      setSquad={setSquad}
      ref={wrapperRef}
    />
  );
});

export class SofeOverrides extends React.Component {
  state = {
    error: false,
    services: {},
    overrides: {},
    search: "",
  };

  els = {};

  componentDidMount() {
    const importMapsNodes = window.document.querySelectorAll(
      `script[type="systemjs-importmap"]`,
    );
    if (importMapsNodes.length === 0) {
      this.refreshServices();

      getAvailableServices()
        .then((services) => {
          window.sofeServices = services;
          this.setState({ services });
        })
        .catch(() => {
          this.setState({ error: true });
        });
    } else {
      let services = {};
      importMapsNodes.forEach((node) => {
        const importJson = JSON.parse(node.innerHTML).imports;
        // Check for extra slash like "tasks-ui/!sofe"
        // and externals submodule like "tasks-ui/externals!sofe"
        const regex = new RegExp(/\/(?:externals)?\!/g);
        const filteredJson = Object.keys(importJson)
          .filter((key) => !key.match(regex))
          .reduce((acc, key) => ((acc[key] = importJson[key]), acc), {});
        services = { ...services, ...filteredJson };
      });

      const overrides = {};
      for (let storageKey in localStorage) {
        if (storageKey.includes("sofe:")) {
          let overrideKey = storageKey.slice(5);
          overrides[overrideKey] = localStorage[storageKey];
        }
      }

      this.setState({ services, overrides });
    }
  }

  render() {
    const { services, overrides, search, error } = this.state;
    const { filteredSquad } = this.props;

    const squads = groupBy(Object.keys(services), serviceNameToSquad) || {};

    if (error) return <h3>Oops, something went wrong with sofe overrides!</h3>;

    const filteredServices = chain(Object.keys(services).sort())
      .filter(
        (service) =>
          !isEmpty(search.trim()) ||
          !filteredSquad ||
          filteredSquad === "null" ||
          (squads[filteredSquad] || []).includes(service) ||
          has(overrides, service),
      )
      .filter((service) => service.includes(search))
      .value();

    const numOverrides = filteredServices.filter((service) =>
      has(overrides, service),
    ).length;

    return (
      <div
        style={{ position: "relative", height: "100%" }}
        ref={(el) => (this.wrapper = el)}
      >
        <ServiceFilter
          filteredSquad={filteredSquad}
          setSquad={this.props.setSquad}
          changeSearch={(e) => this.setState({ search: e.target.value })}
          search={search}
          squads={squads}
          visible={this.props.visible}
          ref={(el) => (this.serviceFilterComponent = el)}
        />
        <div
          style={{
            overflow: "auto",
            height: 320,
            border: "1px solid #464545",
            padding: 8,
          }}
        >
          {sortBy(filteredServices, (service) =>
            has(overrides, service) ? `a${service}` : `b${service}`,
          ).map((service, index) => {
            const isLastServiceWithOverride = index === numOverrides - 1;

            const hasOverride = has(overrides, service);
            const stage = overrides[service] === "cdn-stage";
            const integ = overrides[service] === "cdn-integ";
            const prod = overrides[service] === "prod";
            const customOverride = hasOverride && !stage && !integ && !prod;

            return (
              <div
                key={service}
                style={{
                  marginBottom: 8,
                  paddingBottom: 4,
                  display: "flex",
                  alignItems: "center",
                  borderBottom: isLastServiceWithOverride
                    ? "1px solid rgb(255, 48, 91)"
                    : "1px solid #464545",
                }}
              >
                <span style={{ width: 200 }}>{this.getName(service)}</span>
                <label
                  className="cps-radio"
                  style={{ position: "relative", top: 2 }}
                >
                  <input
                    type="radio"
                    value="1"
                    name={service}
                    checked={!hasOverride}
                    onChange={this.removeOverride.bind(this, service)}
                  />
                  <span style={{ paddingTop: 6 }}>Default</span>
                </label>
                <label
                  className="cps-radio"
                  style={{ position: "relative", top: 2 }}
                >
                  <input
                    type="radio"
                    value="0"
                    name={service}
                    checked={integ}
                    onChange={this.setOverride.bind(this, service, "cdn-integ")}
                  />
                  <span style={{ paddingTop: 6 }}>Integ</span>
                </label>
                <label
                  className="cps-radio"
                  style={{ position: "relative", top: 2 }}
                >
                  <input
                    type="radio"
                    value="0"
                    name={service}
                    checked={stage}
                    onChange={this.setOverride.bind(this, service, "cdn-stage")}
                  />
                  <span style={{ paddingTop: 6 }}>Stage</span>
                </label>
                <label
                  className="cps-radio"
                  style={{ position: "relative", top: 2 }}
                >
                  <input
                    type="radio"
                    value="0"
                    name={service}
                    checked={prod}
                    onChange={this.setOverride.bind(this, service, "prod")}
                  />
                  <span style={{ paddingTop: 6 }}>Prod</span>
                </label>
                <label
                  className="cps-radio"
                  style={{ position: "relative", top: 2 }}
                >
                  <input
                    type="radio"
                    value="0"
                    name={service}
                    onChange={this.setCustomOverride.bind(this, service)}
                    checked={customOverride}
                  />
                  <span style={{ paddingTop: 6, marginRight: 16 }}>Custom</span>
                </label>

                {customOverride && (
                  <input
                    type="text"
                    value={
                      overrides[service] ||
                      localStorage.getItem("sofe-default-port:" + service)
                    }
                    ref={(el) => (this.els[service] = el)}
                    onChange={this.updateOverrideInput.bind(this, service)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  setCustomOverride(name) {
    updateService(
      name,
      localStorage.getItem("sofe-default-port:" + name) || "",
    ).then(() => {
      this.setState(
        () => ({
          overrides: {
            ...this.state.overrides,
            [name]: "",
          },
        }),
        () => {
          this.els[name].focus();
        },
      );
    });
  }

  setOverride(name, value, focusSearchAfterward = true) {
    updateService(name, value)
      .then(() => {
        this.setState(
          (prevState) => ({
            overrides: { ...prevState.overrides, [name]: value },
          }),
          () => {
            if (focusSearchAfterward) {
              // This helps avoid weird scroll jumping and is nice to be able to immediately start searching after setting an override
              this.serviceFilterComponent.focusSearch();
            }
          },
        );
      })
      .catch(() => this.setState({ error: true }));
  }

  removeOverride(name) {
    removeService(name)
      .then(() => {
        this.setState(
          (prevState) => {
            const newState = { overrides: cloneDeep(prevState.overrides) };
            delete newState.overrides[name];
            return newState;
          },
          () => {
            this.serviceFilterComponent.focusSearch();
          },
        );
      })
      .catch(() => this.setState({ error: true }));
  }

  updateOverrideInput(name, event) {
    this.setOverride(name, event.target.value, false);
    localStorage.setItem("sofe-default-port:" + name, event.target.value);
  }

  getName(serviceName) {
    return (
      <div style={{ color: "white", textDecoration: "none" }}>
        <i
          className="cps-icon cps-icon-circle"
          style={{
            fontSize: "14px",
            paddingRight: "4px",
            color: serviceNameToSquad(serviceName),
          }}
        />
        <span>
          {serviceName.endsWith("!sofe")
            ? serviceName.slice(0, -5)
            : serviceName}
        </span>
      </div>
    );
  }

  refreshServices = () => {
    getServiceOverrides()
      .then((overrides) => this.setState({ overrides }))
      .catch(() => this.setState({ error: true }));
  };
}
