import React from "react";
import { isEmpty, mapKeys, chain } from "lodash";
import {
  updateService,
  clearAllSofeOverrides,
} from "./sofe/service.resource.js";
import {
  setToggle,
  clearAllToggleOverrides,
} from "./feature/feature-toggles.resource.js";
import { forkJoin, from } from "rxjs";

export default class Snapshots extends React.Component {
  componentWillUnmount() {
    this.cleanSet && this.cleanSet.unsubscribe();
  }

  render() {
    const { snapshots } = this.props;
    const snapKeys = Object.keys(snapshots);
    return (
      <div style={{ position: "relative", height: "100%" }}>
        {!isEmpty(snapshots)
          ? snapKeys.reduce(
              (html, key) => [
                ...html,
                <div
                  key={`snap${key}${Math.floor(Math.random() * 10)}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 10,
                    borderBottom: "1px solid #fff",
                  }}
                >
                  <input
                    type="text"
                    className="cps-form-control"
                    defaultValue={`${key}: ${JSON.stringify(snapshots[key])}`}
                    style={{ maxWidth: "50%" }}
                    value={undefined}
                    disabled
                  />
                  <a
                    style={{ marginLeft: 15 }}
                    onClick={() => this.applySnapshot(key)}
                  >
                    <i className="cps-icon cps-icon-valid" />
                  </a>

                  <a
                    style={{ marginLeft: 15 }}
                    onClick={() => this.deleteSnapshot(key)}
                  >
                    <i className="cps-icon cps-icon-trash" />
                  </a>
                </div>,
              ],
              [],
            )
          : "No Snapshots"}
      </div>
    );
  }

  deleteSnapshot = (index) => {
    const store = "sofe-snapshots";
    const withoutSnap = chain(JSON.parse(localStorage.getItem(store)))
      .omit([index])
      .reduce((acc, val) => ({ ...acc, [Object.keys(acc).length]: val }), {})
      .value();

    if (isEmpty(withoutSnap)) {
      localStorage.removeItem(store);
    } else {
      localStorage.setItem(store, JSON.stringify(withoutSnap));
    }

    this.props.updateSnaps(withoutSnap);
  };

  applySnapshot = (index) => {
    const snap = this.props.snapshots[index];
    this.cleanSet = forkJoin(
      from(clearAllSofeOverrides()),
      from(clearAllToggleOverrides()),
    ).subscribe(() => {
      mapKeys(snap.toggles, (val, key) => setToggle(key, val));
      mapKeys(snap.overrides, (val, key) => updateService(key, val));
    });
  };
}
