import { fetchFeatureToggles } from "feature-toggles!sofe";

export function getToggles() {
  return fetch("https://tm.canopytax.com/api/features")
    .then((resp) => {
      if (!resp.ok)
        throw new Error(`Error fetching features, status: ${resp.status}`);
      return resp.json();
    })
    .then((json) =>
      fetchFeatureToggles(...json.features.map((feature) => feature.name), {
        metrics: false,
      }),
    );
}

export function getToggleOverrides() {
  return new Promise((resolve) => {
    let services = {};

    for (let i = 0, iLength = localStorage.length; i < iLength; i++) {
      if (localStorage.key(i).match(/feature:(\S)+/g)) {
        services[/feature:((\S)+)/g.exec(localStorage.key(i))[1]] =
          localStorage.getItem(localStorage.key(i));
      }
    }
    resolve(services);
  });
}

export function setToggle(name, val) {
  return new Promise((resolve) => {
    localStorage.setItem(`feature:${name}`, val);
    resolve();
  });
}

export function removeToggle(name) {
  return new Promise((resolve) => {
    localStorage.removeItem(`feature:${name}`);
    resolve();
  });
}

export function clearAllToggleOverrides() {
  return getToggles().then((features) =>
    Object.keys(features).forEach((featureName) => removeToggle(featureName)),
  );
}
